<template>
  <ReportDatePicker @date-updated="submitDate"/>
	<div v-if="info != null">
		<div v-if="info.status == 'success'">
			<v-tabs show-arrows v-if="titles.length > 1" class="d-print-none" :items="titles" v-model="model" />

			<v-window v-model="model">
				<v-window-item v-for="report in info.report" :key="report.location.name" :value="report.location.name">
				<div class="mx-1 mt-3">
					<h2>{{ report.location.name }}</h2>
					<h4 v-if="report.fulfill_date_summary.orders_count == 0 && report.fulfill_date_summary.items_count == 0">
						No orders found for {{ report.fulfill_date_summary.fulfill_date }}
					</h4>
					<h4 v-else>
						<span v-if="report.fulfill_date_summary.orders_count > 0">{{ report.fulfill_date_summary.orders_count }} orders</span>
						<span v-if="report.fulfill_date_summary.items_count > 0">, {{ report.fulfill_date_summary.items_count }} items</span>
					</h4>
					<v-card
						class="my-4"
						elevation="2"
						v-if="report.items_summary.length > 0"
					>
						<v-card-header>
							<h3>Items Summary</h3>
						</v-card-header>
						<v-card-text>
							<ItemSummary :itemSummary="report.items_summary"/>
						</v-card-text>
					</v-card>
					<div v-if="report.orders.length > 0">
						<h3>Orders</h3>
						<v-card
							class="my-4"
							elevation="2"
							v-for="order in report.orders" :key="order.id"
						>							
							<v-card-header class="justify-space-between">
								<div>
									<strong>Order {{ order.name }}</strong>
									<v-chip v-if="order.cancelled_at != null" size="small" class="custom-color-chip text-capitalize ml-2" :style="{ backgroundColor: '#ffd79d' }">Cancelled</v-chip>
									<v-chip v-if="order.financial_status != null" size="small" class="text-capitalize ml-2">{{ order.financial_status }}</v-chip>
									<v-chip size="small" class="custom-color-chip text-capitalize ml-2"
									v-bind:style= "[order.fulfillment_status == null ? { backgroundColor: '#ffea8a' } : { backgroundColor: '#e5e5e5'}]"><span v-if="order.fulfillment_status != null">{{ order.fulfillment_status }}</span><span v-else>unfulfilled</span></v-chip>
								</div>
								<v-btn class="bg-primary" size="small" target="_blank" :href="order.admin_edit_url">
									Edit Order
									<v-icon right>
									mdi-open-in-new
									</v-icon>
								</v-btn>
							</v-card-header>
							<v-card-text>
								<CustomerAddress :address="order.shipping_address" />								
								<ItemSummary class="mt-2" :itemSummary="order.line_items"/>
							</v-card-text>
						</v-card>
					</div>
				</div>
				</v-window-item>
			</v-window>
		</div>
		<div v-else-if="info.status == 'error'">
			<strong class="text-error">{{ info.error }}</strong>
		</div>
		<div v-if="error != null">
			<strong class="text-error">{{ error }}</strong>
		</div>
	</div>
</template>

<script>
import ReportDatePicker from './ReportDatePicker.vue'
import ItemSummary from './ItemSummary.vue'
import CustomerAddress from './CustomerAddress.vue'
import { ref } from 'vue'
import axios from 'axios'

export default {
	data: () => ({
		info: null,
		error: null,
		titles: []
	}),
	components: {
		ReportDatePicker,
		ItemSummary,
		CustomerAddress
	},
	methods: {
		submitDate(date){
			axios
				.get(`https://us-central1-cfg-cafe-gratitude.cloudfunctions.net/getFulfillmentReport?fulfill_date=${date}`)
				.then(response => {
					this.info = response.data
					this.titles = this.info.report.map((location) => {
						return location.location.name
					})
				})
				.catch(function(error){
					if(error){
						console.log(error.response)
						this.error = error.response
					}
				})
		}
	},
	setup () {
		const tabs = ref(['all',])
		const model = ref('all');

		return {
			tabs,
			model, // starting tab
		}
	}
}
</script>