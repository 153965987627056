<template>
  <v-row class="d-print-none mb-3">
    <v-col cols="8" sm="8" md="3">
      <v-text-field required label="Date" type="date" v-model="date" hide-details="auto"></v-text-field>
    </v-col>
    <v-col cols="4" sm="4" md="3">
      <v-btn color="primary" size="x-large" :disabled="date == ''" @click="$emit('dateUpdated', date)">submit</v-btn>
    </v-col>
  </v-row>
  <v-row class="d-print-block d-none mx-1 mb-2">Fulfillment Reports for {{ date }}</v-row>
</template>

<script>
  export default {
    data: () => ({
      date: ""
    }),
    emits: ['dateUpdated']
  }
</script>