<template>
	<v-table density="compact">
		<thead>
			<tr>
				<th class="text-left">Name</th>
				<th class="text-left">Fulfillable</th>
				<th class="text-left">Fulfilled</th>
				<th class="text-left">Cancelled</th>
				<th class="text-left">Total</th>
			</tr>
		</thead>	
		<tbody>
			<tr v-for="item in itemSummary" :key="item.variant_id">
				<td v-bind:style="{ maxWidth: '600px', width: '600px' }">{{ item.name }}</td>
				<td>{{ item.fulfillable }}</td>
				<td>{{ item.fulfilled }}</td>
				<td>{{ item.cancelled }}</td>
				<td>{{ item.quantity }}</td>
			</tr>
		</tbody>
	</v-table>
</template>

<script>
	export default {
		props: {
			itemSummary: Object
		}
	}
</script>