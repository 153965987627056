<template>
  <v-app>
    <v-main>
      <v-container :style="{ maxWidth: '1000px' }">
        <LocationFulfillmentReport />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LocationFulfillmentReport from './components/LocationFulfillmentReport.vue'

export default {
  name: 'App',

  components: {
    LocationFulfillmentReport,
  },
  
}
</script>