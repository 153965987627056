<template>
	<div>{{ address.name }}</div>
	<div>{{ address.address1 }}</div>
	<div>{{ address.address2 }}</div>
	<div>{{ address.city }}, {{ address.province_code }} {{ address.zip }}</div>
</template>

<script>
	export default {
		props: {
			address: Object
		}
	}
</script>